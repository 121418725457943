<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <div class="d-flex justify-space-between dashboardTopBar">
      <div class="d-flex ms-auto dashboardFilterBar">
        <header-filters
          class-name="dashboard"
          :filter-names="['make', 'model']"
          is-date-show
          :date-selected="false"
          :date-validation="false"
          @onChange="onFiltersChange"
        />
        <div class="marleftCustom">
          <card-options
            :tenant-tech="vehicle_tech"
            @change="callSectionData"
          />
        </div>
      </div>
    </div>
    <!-- <SkeletonLoader v-if="dashboardLoading" /> -->

    <section class="sections-wrapper">
      <section-one
        :loading="sectionOneLoader"
        :options="options"
        :vehicle-tech="vehicle_tech"
      />

      <div class="row marZero">
        <section-two
          :loading="sectionTwoLoader"
          :options="options"
          :vehicle-tech="vehicle_tech"
        />

        <section-three
          :loading="sectionThreeLoader"
          :options="options"
          :vehicle-tech="vehicle_tech"
        />
      </div>
      <section-four
        :loading="sectionFourLoader"
        :options="options"
        :vehicle-tech="vehicle_tech"
      />
    </section>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "DashboardDashboard",
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    SectionOne: () => import("./sections/SectionOne.vue"),
    SectionTwo: () => import("./sections/SectionTwo.vue"),
    SectionThree: () => import("./sections/SectionThree.vue"),
    SectionFour: () => import("./sections/SectionFour.vue"),
    CardOptions: () => import("./utils/CardOptions.vue"),
    // SkeletonLoader: () => import("./utils/SkeletonLoader.vue"),
  },
  data() {
    return {
      drawer: false,
      options: {},
      headers: [
        {
          text: this.$t("fuel_vehicle_cost"),
          align: "left",
          sortable: false,
          value: "fuel_cost",
          // width: "5%",
        },
        {
          text: this.$t("electric_vehicle_cost"),
          align: "left",
          sortable: false,
          value: "electricity_cost",
          // width: "15%",
        },
        {
          text: this.$t("savings"),
          align: "left",
          sortable: false,
          value: "savings",
          // width: "15%",
        },
      ],
      sectionOneLoader: false,
      sectionTwoLoader: false,
      sectionThreeLoader: false,
      sectionFourLoader: false,
      isActiveModal: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantsList",
      isLoadingMdModels: "mdModels/isLoadingMdModels",
      listMdModels: "mdModels/listMdModels",
      isLoading: "dashboard/getIsLoading",
      dashboardLoading: "dashboard/getDashoardLoading",
      dashboadData: "dashboard/getDashboadData",
      dashboadSortData: "dashboard/getDashboadSortData",
      selectedCards: "dashboard/getSelectedCards",
      getCardOptions: "dashboard/getCardOptions",
    }),
    isTwoWheeler() {
      const vType = this.dashboadData.vehicle_type;
      return vType?.length > 0 ? vType.every((r) => r == "two_wheeler") : false;
    },
    vehicle_tech() {
      if (this.authUser.isAdmin) {
        const tId = this.options.tenant_id;
        return this.tenantsList.find((r) => r.id == tId)?.vehicle_tech || "ev"; //when nothing set ev
      } else {
        return this.authUser.user_tech;
      }
    },
  },
  watch: {
    options: {
      handler: async function (v) {
        if (v) {
          await this.getVehicleList();
        }
      },
    },
  },
  beforeDestroy() {
    const params = {
      name: "dashboard",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  async mounted() {},
  methods: {
    async fetchData() {
      this.$store.dispatch("dashboard/setDashboardLoader", true);
      this.sectionOneLoader = true;
      this.sectionTwoLoader = true;
      this.sectionThreeLoader = true;
      this.sectionFourLoader = true;
      const params = {};
      params.vehicle_tech = this.vehicle_tech;
      await this.$store.dispatch("dashboard/dashboardCardOptions", params);
      const data = await this.$store.dispatch("dashboard/userDashboardCards", {
        ...params,
        dashboard_type: "customized",
      });
      this.sectionsAPIs(data, "fetchData");
      this.$store.dispatch("dashboard/setDashboardLoader", false);
      // await this.$store.dispatch("dashboard/dashboardSoc", this.options);
    },
    callSectionData(val, item) {
      this.sectionsAPIs(this.dashboadSortData, val, item);
    },
    async sectionsAPIs(data, val, sectionName = "") {
      const section_1 = data.section_1
        ? data.section_1.map((card) => card.card_name)
        : [];
      const section_2 = data.section_2
        ? data.section_2.map((card) => card.card_name)
        : [];
      const section_3 = data.section_3
        ? data.section_3.map((card) => card.card_name)
        : [];
      const section_4 = data.section_4
        ? data.section_4.map((card) => card.card_name)
        : [];

      // Handle the logic based on the value of `val`
      if (val === "cardOption") {
        // Trigger only the specific sectionName API
        switch (sectionName) {
          case "section_1":
            this.$store
              .dispatch("dashboard/sectionOneList", this.options)
              .then(() => (this.sectionOneLoader = false));
            break;
          case "Vehicle Status":
            await this.$store
              .dispatch("dashboard/vechicleStatusList", this.options)
              .then(() => (this.sectionTwoLoader = false));
            break;
          case "Trips":
            await this.$store
              .dispatch("dashboard/vechicleTrips", this.options)
              .then(() => (this.sectionTwoLoader = false));
            break;
          case "Electric Vehicle Cost":
            await this.$store
              .dispatch("dashboard/dashboardElectricCost", this.options)
              .then(() => (this.sectionThreeLoader = false));
            break;
          case "Fuel Vehicle Cost":
            await this.$store
              .dispatch("dashboard/dashboardFuelCost", this.options)
              .then(() => (this.sectionThreeLoader = false));
            break;
          case "Savings":
            await this.$store
              .dispatch("dashboard/dashboardSaving", this.options)
              .then(() => (this.sectionThreeLoader = false));
            break;
          case "section_4":
            this.$store
              .dispatch("dashboard/sectionFourList", this.options)
              .then(() => (this.sectionFourLoader = false));
            break;
          default:
            break;
        }
      } else if (val === "fetchData") {
        // Trigger all the APIs for all sections
        if (section_1.length > 0) {
          this.$store
            .dispatch("dashboard/sectionOneList", this.options)
            .then(() => (this.sectionOneLoader = false));
        }
        if (section_4.length > 0) {
          this.$store
            .dispatch("dashboard/sectionFourList", this.options)
            .then(() => (this.sectionFourLoader = false));
        }

        if (section_2.includes("Vehicle Status")) {
          await this.$store.dispatch(
            "dashboard/vechicleStatusList",
            this.options
          );
        }
        if (section_2.includes("Trips")) {
          await this.$store.dispatch("dashboard/vechicleTrips", this.options);
        }
        this.sectionTwoLoader = false;

        if (section_3.includes("Electric Vehicle Cost")) {
          await this.$store.dispatch(
            "dashboard/dashboardElectricCost",
            this.options
          );
        }
        if (section_3.includes("Fuel Vehicle Cost")) {
          await this.$store.dispatch(
            "dashboard/dashboardFuelCost",
            this.options
          );
        }
        if (section_3.includes("Savings")) {
          await this.$store.dispatch("dashboard/dashboardSaving", this.options);
        }
        this.sectionThreeLoader = false;
      }
    },

    onFiltersChange(v) {
      delete this.options.product;
      delete this.options.fleetId;
      delete this.options.oem_b2b_ids;
      delete this.options.tenant_id;
      this.options = { ...this.options, ...v };
      const initialBreadcrumb = [
        {
          date: "Reset",
          start: this.options.date ? this.options.date[0] : null,
          end: this.options.date ? this.options.date[1] : null,
        },
      ];
      this.$store.dispatch("dashboard/setTripReset", initialBreadcrumb);
      this.$store.dispatch("dashboard/setTripBreadCrumb", []);
      this.fetchData();
    },
    async getVehicleList() {
      // when tenant exist
      if (this.options.tenant_id) {
        const params = { ...this.options, vehicles_mqtt_data: true };
        await this.$store.dispatch("vehicles/list", params);
      }
    },
    goToTelematics(val) {
      const params = {};
      if (this.authUser.isAdmin) {
        if (this.options.product == "oem") {
          params.product = this.options.product;
          params.tenant_id = this.options.tenant_id;
        } else if (this.options.product == "fleet") {
          params.product = this.options.product;
          params.fleetId = this.options.fleetId;
          params.oem_b2b_ids = this.options.oem_b2b_ids;
          params.tenant_id = this.options.tenant_id;
        }
      } else if (this.authUser.user_nature == "fleet") {
        params.tenant_id = this.options.tenant_id;
      }
      if (val) {
        params.status = val;
      }
      return this.$router.push({
        name: "vehicle_telematics",
        query: params,
      });
    },
    activeTripAction() {
      this.isActiveModal = true;
    },
  },
};
</script>
<style lang="sass" scoped>
.dashboardTopBar
  padding: 0 12px
  align-items: center

#dashboard
  padding: 12px

.sections-wrapper
  display: flex
  gap: 1rem
  flex-direction: column

:deep .v-select
  .v-select__selections
    span
      width: 70px
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis

@media only screen and (max-width: 960px)
  .sections-wrapper
    flex-direction: column

//   #content-wrapper
//     .content-container
//       flex-direction: column
//       .box-container
//         order: 2
//       .tags-container
//         order: 3
//       .mapAndTop5
//         order: 1
</style>
/* Small devices (portrait tablets and large phones, 600px and up) */ @media
only screen and (min-width: 600px) { .box { width: 250px !important; } }
